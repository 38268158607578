.hero-image {
  background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("citibikes.jpg");
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.badge {
  width: 50vh;
}